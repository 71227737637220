<template>
    <div class="public_charity">
        <img style="width: 100%;" src="@/assets/index/banner.jpg" alt=""> 
        <div class="public_charity_item">
            <div class="public_charity_item_one">
                <div class="public_charity_item_one_text">
                    <div class="public_charity_item_one_text_title">秦皇岛市邢台商会赴农村参加公益慰问活动</div>
                    <div class="public_charity_item_one_text_date">2022-07-25</div>
                </div>
                <div>
                    <img style="width: 96%;" src="@/assets/index/1.jpg" alt=""> 
                </div>
            </div>
            <div class="public_charity_item_one">
                <div class="public_charity_item_one_text">
                    <div class="public_charity_item_one_text_title">秦皇岛市邢台商会会员监督代表齐栋利组织慰问老兵</div>
                    <div class="public_charity_item_one_text_date">2022-07-30</div>
                </div>
                <div>
                    <img style="width: 96%;" src="@/assets/index/2.jpg" alt=""> 
                </div>
            </div>
            <div class="public_charity_item_one">
                <div class="public_charity_item_one_text">
                    <div class="public_charity_item_one_text_title">秦皇岛市邢台商会赴驻秦91202部队官兵慰问</div> 
                    <div class="public_charity_item_one_text_date">2022-08-01</div>
                </div>
                <div>
                    <img style="width: 96%;" src="@/assets/index/3.jpg" alt=""> 
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
  defineComponent
} from "@/plugin/importCommonUse";
import { computed } from 'vue'
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
    setup() {
        let route = useRoute();
        let router = useRouter();
        let init = computed(()=>{ 

        })
        let skip = (url)=>{

        }
        return {
        };
    },
})
</script>
<style>
.public_charity{
}
.public_charity_item{ 
}
.public_charity_item_one_text{
    padding: 20px 0 10px 0;
    background: #fff;
    margin:0 2% 0 2%;
}
.public_charity_item_one_text_title{
    /* width: 75%; */
    text-align: left;
    /* overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    display: inline-block; */
    
}
.public_charity_item_one_text_date{
    text-align: right;
    font-size: 14px;
}
</style>
